<template>
  <el-row style="width: 100%">
    <el-col :span="6">
      <el-tree
        :default-checked-keys="[-1]"
        :data="partitionData"
        ref="treeForm"
        :highlight-current="true"
        default-expand-all
        @node-click="changetree"
        :props="defaultProps"
        node-key="index"
        style="
          width: 100%;
          float: left;
          overflow-y: auto;
          margin-right: 5%;
          background: transparent;
        "
      >
        <template #default="{ node, data }">
          <div class="custom-tree-node">
            <img
              width="24"
              src="../assets/image/folder.png"
              alt=""
              srcset=""
            />&nbsp;&nbsp;{{ node.label }}
          </div>
        </template>
      </el-tree>
    </el-col>

    <el-col :span="18" style="padding-left: 20px; height: 80vh; background: #fff">
      <el-button style="margin-top: 10px" @click="checkBtn" type="text"
        >全选/取消全选</el-button
      >
      <el-button @click="searchBtn" style="float: right; margin: 10px 10px 0 0"
        >搜索</el-button
      >
      <el-input
        style="width: 160px; float: right; margin: 10px 10px 0 0"
        v-model="keyword"
        placeholder="请输入搜索关键字"
      >
      </el-input>
      <ul class="audio-box" style="height: calc(100% - 100px)">
        <li
          class="audio-list"
          v-for="(item, index) in devicelist"
          @click.stop="checkRow(index)"
          :key="index"
        >
          <div class="audio-top">
            <img
              style="width: 14px; float: left; height: 14px"
              :src="item[6] ? checked2 : checkout2"
              alt=""
              srcset=""
            />
            <div style="width: 55%; height: 15px; line-height: 15px" class="floatRight">
              <template v-if="item[0]"> <i class="green-icon"></i> 在线 </template>
              <template v-else> <i class="red-icon"></i> 离线 </template>
            </div>
          </div>
          <div class="centerBox">
            <div :class="item[6] == true ? 'bluebg' : ''">
              <img width="26" src="../assets/image/laba.png" alt="" srcset="" />
            </div>
          </div>
          <div class="fontFoot">
            <p
              :title="item[2]"
              style="
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                -o-text-overflow: ellipsis;
              "
            >
              {{ item[2] }}
            </p>
          </div>
        </li>
      </ul>
    </el-col>
  </el-row>
  <!-- v-infinite-scroll="loadMore" 分页-->
</template>

<script>
import { toRaw } from "vue";
import {
  getAreaDegree,
  getAreaDegree2,
  getDeviceByArea,
  getDeviceByArea2,
} from "../utils/api";
export default {
  components: {},
  props: {
    echoData: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      treeCheNum: -1,
      keyword: "",
      showMore: false,
      searchType: -1,
      defaultProps: {
        label: "name",
        children: "children",
      },
      partitionData: [],
      devicelist: [],
      pageNo: 0,
      isloadMore: true,

      isAll: false,
      checked2: require("../assets/image/checked2.png"),
      checkout2: require("../assets/image/checkout2.png"),

      checkDevice: [],
    };
  },
  watch: {
    echoData: {
      handler(newV) {
        let that = this;
        setTimeout(function () {
          var device = JSON.parse(JSON.stringify(that.devicelist));
          var newVData = JSON.parse(JSON.stringify(newV));
          if (newVData && newVData.length > 0) {
            that.devicelist = device.map((element) => {
              // newVData.map((f) => {
              //   if (element[1] == f) {
              //     element[6] = true;
              //   }
              // });
              if(newVData.indexOf(element[1]) > -1){
                element[6] = true;
              }
              return element;
            });
          } else {
            that.devicelist = device.map((element) => {
              element[6] = false;
              return element;
            });
          }
        }, 1000);
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {},
  methods: {
    searchBtn() {
      if (this.keyword) {
        this.devicelist = this.devicelist.filter((f) => {
          return f[1].indexOf(this.keyword) > -1;
        });
      } else {
        this.getDeviceList(this.treeCheNum);
      }
    },
    pushData() {
      this.pageNo = this.pageNo + 1;
      let adminPage = ["/equipmentList", "/userManagementAdmin", "/basicSetup"];
      if (adminPage.indexOf(this.$route.path) > -1) {
        getDeviceByArea2({ params: [-1, -1] }).then((res) => {
          this.devicelist = this.devicelist.concat(res.result[0]);

          if (res.result[0].length < res.result[1]) {
            this.showMore = true;
          }
          if (res.result[0].length == 0) {
            this.showMore = false;
          }
          //每次加载更多重新设备的选中
          this.devicelist = this.devicelist.map((m) => {
            this.echoData.map((m2) => {
              if (this.$route.path == "/userManagementAdmin") {
                if (m[1] == m2) {
                  m[6] = true;
                }
              } else {
                if (m[1] == m2[1]) {
                  m[6] = true;
                }
              }
            });
            return m;
          });
        });
      } else {
        getDeviceByArea({ params: [-1, -1] }).then((res) => {
          this.devicelist = this.devicelist.concat(res.result[0]);

          if (res.result[0].length < res.result[1]) {
            this.showMore = true;
          }
          if (res.result[0].length == 0) {
            this.showMore = false;
          }
          //每次加载更多重新设备的选中
          this.devicelist = this.devicelist.map((m) => {
            this.echoData.map((m2) => {
              if (this.$route.path == "/userManagementAdmin") {
                if (m[1] == m2) {
                  m[6] = true;
                }
              } else {
                if (m[1] == m2[1]) {
                  m[6] = true;
                }
              }
            });
            return m;
          });
        });
      }
    },

    checkBtn() {
      this.devicelist.map((v) => {
        if (this.isAll) {
          v[6] = false;
        } else {
          v[6] = true;
        }
      });
      this.checkDevice = this.devicelist.filter((v) => v[6] === true).map((m) => m[1]);
      this.isAll = !this.isAll;
    },
    
    changetree(data, lst) {
      this.pageNo = 0;
      this.treeCheNum = data.index;
      let adminPage = ["/equipmentList", "/userManagementAdmin", "/basicSetup"];
      if (adminPage.indexOf(this.$route.path) > -1) {
        getDeviceByArea2({ params: [data.index, -1] }).then((res) => {
          this.devicelist = [];
          this.devicelist = res.result[0].map((m) => {
            if (this.checkDevice.indexOf(m[1]) > -1) {
              m[6] = true;
            } else {
              m[6] = false;
            }
            return m;
          });

          this.$forceUpdate();
        });
      } else {
        getDeviceByArea({ params: [data.index, -1] }).then((res) => {
          this.devicelist = [];
          this.devicelist = res.result[0].map((m) => {
            m[6] = null;
            // if (this.checkDevice.indexOf(m[1]) > -1) {
            if (this.checkDevice.indexOf(m[1]) > -1 || this.echoData.indexOf(m[1]) > -1) {
              m[6] = true;
            } else {
              m[6] = false;
            }
            return m;
          });
          this.$forceUpdate();
        });
      }
    },
    checkRow(i) {
      if (this.devicelist[i][6] == true || this.devicelist[i][6] == false) {
        this.devicelist[i][6] = !this.devicelist[i][6];
      } else {
        this.devicelist[i][6] = true;
      }
      let cherow = JSON.parse(JSON.stringify(this.devicelist[i]));
      if (this.checkDevice.filter((f) => cherow[1] === f).length > 0) {
        this.checkDevice = this.checkDevice.filter((f) => cherow[1] != f);
      } else {
        this.checkDevice.push(cherow[1]);
      }
    },
    getDeviceList(a) {
      let adminPage = ["/equipmentList", "/userManagementAdmin", "/basicSetup"];
      if (adminPage.indexOf(this.$route.path) > -1) {
        getDeviceByArea2({ params: [a, -1] }).then((res) => {
          this.devicelist = [];
          this.devicelist = this.devicelist.concat(res.result[0]);
        });
      } else {
        getDeviceByArea({ params: [a, -1] }).then((res) => {
          this.devicelist = [];
          this.devicelist = this.devicelist.concat(res.result[0]);
        });
      }
    },
  },
  created() {},
  mounted() {
    let adminPage = ["/equipmentList", "/userManagementAdmin", "/basicSetup"];
    if (adminPage.indexOf(this.$route.path) > -1) {
      getAreaDegree2({ params: [] }).then((res) => {
        this.partitionData = res.result ? res.result : [];
      });
    } else {
      getAreaDegree({ params: [] }).then((res) => {
        this.partitionData = res.result ? res.result : [];
      });
    }
    if (adminPage.indexOf(this.$route.path) > -1) {
      getDeviceByArea2({ params: [-1, -1] }).then((res) => {
        this.devicelist = [];
        this.devicelist = this.devicelist.concat(res.result[0]);

        if (res.result[0].length < res.result[1]) {
          this.showMore = true;
        } else if (res.result[0].length == 0) {
          this.showMore = false;
        }
      });
    } else {
      getDeviceByArea({ params: [-1, -1] }).then((res) => {
        this.devicelist = [];
        this.devicelist = this.devicelist.concat(res.result[0]);
        if (res.result[0].length < res.result[1]) {
          this.showMore = true;
        } else if (res.result[0].length == 0) {
          this.showMore = false;
        }
      });
    }
  },
  unmounted() {
    let device = JSON.parse(JSON.stringify(this.devicelist));
    this.devicelist = device.map((element) => {
      element[6] = false;
      return element;
    });
  },
};
</script>
<style lang="scss" scoped>
.audio-box {
  width: 100%;
  font-size: 13px;
  font-weight: 500;
  overflow-y: auto;
  & li {
    border: 1px solid #c8ccd5;
    border-radius: 3px;
    height: 60px;
    padding: 10px;
    box-sizing: border-box;
    margin: 10px 0 0 0;
  }
  & .audio-list {
    margin: 5px;
  }
  & .audio-list {
    width: 100px;
    height: 125px;
    float: left;
  }
}
.green-icon {
  width: 10px;
  height: 10px;
  background: rgb(0, 255, 0);
  display: inline-block;
  border-radius: 10px;
}
.red-icon {
  width: 10px;
  height: 10px;
  background: red;
  display: inline-block;
  border-radius: 10px;
}
.centerBox {
  width: 100%;
  height: 65px;
  margin-top: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  & > div {
    width: 55px;
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #aaa;
    border-radius: 50%;
  }
}

.fontFoot {
  & > p:nth-child(1) {
    text-align: center;
  }
  & > p:nth-child(2) {
    margin-top: 6px;
    line-height: 20px;
    text-align: center;
    width: 100%;
    background: #9199a8;
    color: #fff;
    border-radius: 20px;
  }
}
.floatRight {
  float: right;
}
.bluebg {
  background: #458af6 !important;
}

.custom-tree-node {
  height: 70px;
  line-height: 70px;
  display: flex;
  align-items: center;
}

::v-deep .el-tree-node__content {
  height: 70px;
  line-height: 70px;
}

/deep/ .el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
  background-color: #fff;
}
</style>
